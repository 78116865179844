import { type PostType } from '@Types/Shared/Post';

import Blocks from '@Shared/Blocks/Blocks';
import Layout from '@Shared/Layout/Layout';

import type { NewsOptions } from '@/_types/Blocks/News';
import type {
	SinglePostOptions,
	SinglePostType,
} from '@/_types/Shared/advanced-block-page/PostSingle';
import type { SectionDecorationOptions } from '@/_types/Shared/Common';
import type { Options } from '@/_types/Shared/Options';
import type { QueryPost } from '@/_types/Shared/Query';
import type { SEO } from '@/_types/Shared/Seo';
import { type MenusType, type SiteType } from '@/_types/Shared/SiteDatas';
import Decorations from '@/App/Blocks/_components/Decorations';
import { getSingleBtnStyle } from '@/views/Shared/GlobalStyles/GlobalStyles';
import { __Front as __ } from '@/views/Shared/lib';
import type { Translation } from '@/views/Shared/Translations';

import CardsTypes from './components/CardsTypes';
import SingleBlock from './variants/SingleBlock';
import SingleClassic from './variants/SingleClassic';
import SingleSuperposition from './variants/SingleSuperposition';
import SingleTitleImgAlign from './variants/SingleTitleImgAlign';
import SingleWide from './variants/SingleWide';

type SingleNewsType = {
	post: PostType;
	blocks: any;
	site: SiteType;
	menus: MenusType;
	minos: Options;
	relatedPosts: QueryPost[];
	translations: Translation[];
	image: string;
	seo: SEO;
};

export default function Single(props: SingleNewsType) {
	const { post, relatedPosts, blocks, site, menus, minos, translations, seo } = props;

	const singlePost: SinglePostOptions = { ...minos.layout.news.single };
	const blockNews: NewsOptions = { ...minos.blocks.news.default };
	const sectionDecoration: SectionDecorationOptions = { ...minos.layout.sectionDecoration };

	const propsSingle: SinglePostType = {
		post: post,
		variant: singlePost.variant,
		imgType: singlePost.imgType,
		displayAuthor: singlePost.displayAuthor,
		displayDate: singlePost.displayDate,
		displayTag: singlePost.displayTag,
		singleHeadingFontClassName: {
			title:
				'minos-single-news-heading-title-font-style text-4xl tablet:minos-single-news-heading-title-font-style ',
			text: {
				p: 'minos-single-news-info-font-style text-base tablet:minos-single-news-info-font-style',
			},
		},
	};

	const singleVariations = {
		classic: <SingleClassic {...propsSingle} />,
		block: <SingleBlock {...propsSingle} />,
		superposition: <SingleSuperposition {...propsSingle} />,
		titleImgAlign: <SingleTitleImgAlign {...propsSingle} />,
		wide: <SingleWide {...propsSingle} />,
	};

	let containerStyle = '';

	if (singlePost.variant != 'superposition') {
		containerStyle = 'minos-grid py-3.5 tablet:py-6 desktop:py-12 bg-minos-single-news-bg-color';
	} else {
		containerStyle = '';
	}

	let padding = '';
	if (sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'wave') {
		padding = 'py-16 tablet:py-24 desktop:pb-40 desktop:pt-24';
	} else {
		padding = 'py-3.5 tablet:py-6 desktop:py-12';
	}

	let margin = '';
	if (sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'wave') {
		margin = 'mt-10 mb-20 tablet:mt-20 tablet:mb-40';
	} else {
		margin = 'my-10 tablet:my-20';
	}

	let relatedNewsContainer = '';
	if (singlePost.relatedVariant === 'classic') {
		relatedNewsContainer = `col-start-2 col-end-14 ${padding}`;
	} else {
		relatedNewsContainer = `col-start-2 col-end-14 py-5 sm:col-start-2 tablet:col-start-3 tablet:col-end-13 p-4 tablet:p-6 desktop:p-12 bg-minos-related-news-secondary-color border-2 border-minos-related-news-container-border-color rounded-minos-related-news-container ${margin}`;
	}

	const Props: ViewProps = {
		blocks,
		containerStyle,
		singleVariations,
		singlePost,
		relatedPosts,
		sectionDecoration,
		relatedNewsContainer,
		blockNews,
		minosOptions: minos,
	};

	return (
		<Layout
			blocks={['single-news', 'news', 'editor']}
			seo={seo}
			site={site}
			translations={translations}
			menus={menus}
			minos={minos}
		>
			<View {...Props} />
		</Layout>
	);
}

type ViewProps = {
	blocks: any;
	containerStyle: string;
	singleVariations: any;
	singlePost: SinglePostOptions;
	relatedPosts: QueryPost[];
	sectionDecoration: SectionDecorationOptions;
	relatedNewsContainer: string;
	blockNews: NewsOptions;
	minosOptions: Options;
};

const View = (props: ViewProps) => {
	const {
		blocks,
		containerStyle,
		singleVariations,
		singlePost,
		relatedPosts,
		sectionDecoration,
		relatedNewsContainer,
		blockNews,
		minosOptions,
	} = props;
	return (
		<>
			<article>
				<div data-aos='fade-up' className={containerStyle}>
					{singleVariations[singlePost.variant]}
				</div>
				<Blocks blocks={blocks} />
			</article>

			{relatedPosts && relatedPosts.length > 0 ? (
				<div className='minos-grid relative bg-minos-related-news-bg-color'>
					<Decorations
						isSectionDecorated={sectionDecoration?.isSectionDecorated}
						type={sectionDecoration?.type}
						pathClassName='fill-minos-related-news-bg-color'
					>
						<aside
							data-related-variant={`relatedNews-${singlePost.relatedVariant}`}
							data-aos='fade-up'
							className={`relative z-[11] ${relatedNewsContainer}`}
						>
							<h2 className='minos-related-news-title-font-style mb-6 text-3xl tablet:minos-related-news-title-font-style tablet:mb-12'>
								{__('Related News')}
							</h2>

							<div className='grid grid-cols-4 gap-y-5 tablet:grid-cols-12 tablet:gap-8 '>
								{relatedPosts.map((post: QueryPost) => (
									<CardsTypes
										key={post.ID}
										post={post}
										cardType={blockNews.cardType}
										textLabel={singlePost.relatedTextLabel ? singlePost.relatedTextLabel : __('Read More')}
										variant={blockNews.variant}
										displayDate={blockNews.displayDate}
										displayTag={blockNews.displayTag}
										displayExcerpt={blockNews.displayExcerpt}
										readMoreStyle={getSingleBtnStyle(minosOptions, blockNews.readMoreStyle)}
									/>
								))}
							</div>
						</aside>
					</Decorations>
				</div>
			) : (
				''
			)}
		</>
	);
};
