import Blocks from '@Shared/Blocks/Blocks';
import Layout from '@Shared/Layout/Layout';
import { __Front as __ } from '@Shared/lib';

import type { EventsOptions } from '@/_types/Blocks/Events';
import type {
	eventTimeInfo,
	SingleEventPostType,
	SingleEventsPostOptions,
} from '@/_types/Shared/advanced-block-page/EventSingle';
import type { SectionDecorationOptions } from '@/_types/Shared/Common';
import type { Options } from '@/_types/Shared/Options';
import type { QueryPost } from '@/_types/Shared/Query';
import type { SEO } from '@/_types/Shared/Seo';
import { type MenusType, type SiteType } from '@/_types/Shared/SiteDatas';
import Decorations from '@/App/Blocks/_components/Decorations';
import { getSingleBtnStyle } from '@/views/Shared/GlobalStyles/GlobalStyles';
import type { Translation } from '@/views/Shared/Translations';

import CardsTypes from './components/CardsTypes';
import SingleBlock from './variants/SingleBlock';
import SingleClassic from './variants/SingleClassic';
import SingleSuperposition from './variants/SingleSuperposition';
import SingleTitleImgAlign from './variants/SingleTitleImgAlign';
import SingleWide from './variants/SingleWide';

type SingleEventsType = {
	post: QueryPost;
	blocks: any;
	site: SiteType;
	menus: MenusType;
	minos: Options;
	relatedEvents: QueryPost[];
	dates: eventTimeInfo[];
	translations: Translation[];
	seo: SEO;
};

export default function Single(props: SingleEventsType) {
	const { post, blocks, site, menus, minos, translations, relatedEvents, dates, seo } = props;

	const singleEvent: SingleEventsPostOptions = { ...minos.layout.events.single };
	const blockEvents: EventsOptions = { ...minos.blocks.events.default };
	const sectionDecoration: SectionDecorationOptions = { ...minos.layout.sectionDecoration };

	const propsSingle: SingleEventPostType = {
		post: post,
		variant: singleEvent.variant,
		imgType: singleEvent.imgType,
		iconType: singleEvent.iconType,
		dates: dates,
		eventsInfoContainerType: singleEvent.eventsInfoContainerType,
		displayDate: singleEvent.displayDate,
		displayOrganizer: singleEvent.displayOrganizer,
		displayPlace: singleEvent.displayPlace,
		displayPrice: singleEvent.displayPrice,
		displayTag: singleEvent.displayTag,
		displaySite: singleEvent.displaySite,
		singleHeadingFontClassName: {
			title:
				'minos-single-events-heading-title-font-style text-4xl tablet:minos-single-events-heading-title-font-style',
			text: {
				h1: 'minos-single-events-title-info-font-style text-xl tablet:minos-single-events-title-info-font-style',
				p: 'minos-single-events-info-font-style text-base tablet:minos-single-events-info-font-style',
			},
		},
	};

	const singleVariations = {
		classic: <SingleClassic {...propsSingle} />,
		block: <SingleBlock {...propsSingle} />,
		superposition: <SingleSuperposition {...propsSingle} />,
		titleImgAlign: <SingleTitleImgAlign {...propsSingle} />,
		wide: <SingleWide {...propsSingle} />,
	};

	let padding = '';
	if (sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'wave') {
		padding = 'py-16 tablet:py-24 desktop:pb-40 desktop:pt-24';
	} else {
		padding = 'py-3.5 tablet:py-6 desktop:py-12';
	}

	let margin = '';
	if (sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'wave') {
		margin = 'mt-10 mb-20 tablet:mt-20 tablet:mb-40';
	} else {
		margin = 'my-10 tablet:my-20';
	}

	let containerStyle = '';
	if (singleEvent.variant != 'superposition') {
		containerStyle = 'minos-grid py-3.5 tablet:py-6 desktop:py-12 bg-minos-single-events-bg-color';
	} else {
		containerStyle = '';
	}

	let relatedEventsContainer = '';
	if (singleEvent.relatedVariant === 'classic') {
		relatedEventsContainer = `col-start-2 col-end-14 ${padding}`;
	} else {
		relatedEventsContainer = `col-start-2 col-end-14 tablet:col-start-3 tablet:col-end-13 p-4 tablet:p-6 desktop:p-12 bg-minos-related-events-secondary-color border-2 border-minos-related-events-container-border-color rounded-minos-related-events-container ${margin}`;
	}

	const viewProps: ViewProps = {
		containerStyle,
		singleVariations,
		singleEvent,
		blocks,
		relatedEvents,
		sectionDecoration,
		relatedEventsContainer,
		blockEvents,
		minosOptions: minos,
	};

	return (
		<Layout
			blocks={['single-events', 'events', 'editor']}
			seo={seo}
			site={site}
			translations={translations}
			menus={menus}
			minos={minos}
		>
			<View {...viewProps} />
		</Layout>
	);
}

type ViewProps = {
	containerStyle: string;
	singleVariations: any;
	singleEvent: SingleEventsPostOptions;
	blocks: any;
	relatedEvents: QueryPost[];
	sectionDecoration: SectionDecorationOptions;
	relatedEventsContainer: string;
	blockEvents: EventsOptions;
	minosOptions: Options;
};

const View = (props: ViewProps) => {
	const {
		containerStyle,
		singleVariations,
		singleEvent,
		blocks,
		relatedEvents,
		sectionDecoration,
		relatedEventsContainer,
		blockEvents,
		minosOptions,
	} = props;
	return (
		<>
			<article>
				<div
					data-aos='fade-up'
					className={containerStyle}
					data-single={`single-${singleVariations[singleEvent.variant]}`}
				>
					{singleVariations[singleEvent.variant]}
				</div>
				<Blocks blocks={blocks} />
			</article>

			{relatedEvents && relatedEvents.length > 0 ? (
				<div className='minos-grid relative bg-minos-related-events-bg-color'>
					<Decorations
						isSectionDecorated={sectionDecoration?.isSectionDecorated}
						type={sectionDecoration?.type}
						pathClassName='fill-minos-related-events-bg-color'
					>
						<aside
							data-related-variant={`relatedevents-${singleEvent.relatedVariant}`}
							data-aos='fade-up'
							className={`relative z-[11] ${relatedEventsContainer}`}
						>
							<h2 className='minos-related-events-title-font-style mb-6 text-3xl tablet:minos-related-events-title-font-style tablet:mb-12'>
								{__('Related Events')}
							</h2>

							<div className='grid grid-cols-4 gap-y-5 tablet:grid-cols-12 tablet:gap-8 '>
								{relatedEvents.map((post: QueryPost) => (
									<CardsTypes
										key={post.ID}
										post={post}
										cardType={blockEvents.cardType}
										textLabel={singleEvent.relatedTextLabel ? singleEvent.relatedTextLabel : __('Read More')}
										variant={blockEvents.variant}
										iconType={blockEvents.iconType}
										displayExcerpt={blockEvents.displayExcerpt}
										displayDate={blockEvents.displayDate}
										displayPlace={blockEvents.displayPlace}
										displayOrganizer={blockEvents.displayOrganizer}
										displayTag={blockEvents.displayTag}
										displayPrice={blockEvents.displayPrice}
										readMoreStyle={getSingleBtnStyle(minosOptions, blockEvents.readMoreStyle)}
									/>
								))}
							</div>
						</aside>
					</Decorations>
				</div>
			) : (
				''
			)}
		</>
	);
};
