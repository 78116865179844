import type { CardNewsType, NewsVariations } from '@/_types/Blocks/News';
import { CardClassicNews } from '@/App/Blocks/News/Front/variants/Classic';
import { CardTagImgNews } from '@/App/Blocks/News/Front/variants/TagImg';
import { CardTitleImgNews } from '@/App/Blocks/News/Front/variants/TitleImg';

type Card = CardNewsType & {
	variant: NewsVariations;
};

export default function CardsTypes(props: Card) {
	const {
		post,
		textLabel,
		cardType,
		variant,
		displayDate,
		displayExcerpt,
		displayTag,
		readMoreStyle,
	} = props;

	const propsBlockNews: CardNewsType = {
		post: post,
		textLabel: textLabel,
		cardType: cardType,
		displayDate: displayDate,
		displayTag: displayTag,
		displayExcerpt: displayExcerpt,
		readMoreStyle: readMoreStyle,
		contentFontClass: {
			postTitle:
				'minos-news-inside-title-font-style text-2xl   tablet:minos-news-inside-title-font-style',
			excerpt:
				'minos-news-inside-description-font-style text-base tablet:minos-news-inside-description-font-style',
			postInfo: 'minos-news-post-info-font-style text-sm tablet:minos-news-post-info-font-style',
			readMore: 'minos-news-readMore-font-style text-base tablet:minos-news-readMore-font-style',
		},
	};

	const blockNewsVariations = {
		classic: <CardClassicNews {...propsBlockNews} />,
		tagImg: <CardTagImgNews {...propsBlockNews} />,
		titleImg: <CardTitleImgNews {...propsBlockNews} />,
	};

	return <>{blockNewsVariations[variant]}</>;
}
