import type { eventTimeInfo } from '@/_types/Shared/advanced-block-page/EventSingle';
import type {
	ContainerTypeEnums,
	ContentClass,
	IconTypeEnums,
	PostSingleVariations,
} from '@/_types/Shared/Common';
import type { ImgFormatVariations } from '@/_types/Shared/Image';
import type { QueryPost } from '@/_types/Shared/Query';

import EventsInfo from '../components/EventsInfo';

type SingleTitleImgAlignProps = {
	post: QueryPost;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	imgType?: ImgFormatVariations;
	dates?: eventTimeInfo[];
	iconType?: IconTypeEnums;
	eventsInfoContainerType?: ContainerTypeEnums;
	displayOrganizer?: boolean;
	displayDate?: boolean;
	displayTag?: boolean;
	displayPrice?: boolean;
	displayPlace?: boolean;
	displaySite?: boolean;
};

export default function SingleTitleImgAlign(props: SingleTitleImgAlignProps) {
	const {
		post,
		variant = 'titleImgAlign',
		singleHeadingFontClassName,
		imgType = 'squared',
		dates,
		iconType,
		eventsInfoContainerType,
		displayDate,
		displayOrganizer,
		displayPlace,
		displayPrice,
		displayTag,
		displaySite,
	} = props;

	let containerStyle = '';
	if (eventsInfoContainerType === 'block') {
		containerStyle =
			'rounded-minos-single-events-info border-2 bg-minos-single-events-info-bg-color border-minos-single-events-info-border-color p-3 tablet:p-6';
	} else {
		containerStyle = '';
	}

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative z-[11] col-start-2 col-end-12 grid grid-cols-4 gap-8 sm:col-end-14 tablet:col-start-3 tablet:grid-cols-12`}
		>
			<div className='relative col-span-full tablet:col-span-6 tablet:col-start-1'>
				{post.image && post.image.url ? (
					<>
						<img
							className={`aspect-square size-full object-cover ${
								imgType === 'squared' ? 'rounded-minos-single-events-img' : 'rounded-full'
							}`}
							src={post.image.url}
							alt=''
						/>
					</>
				) : (
					''
				)}
			</div>

			<div className='col-span-full flex flex-col justify-center overflow-hidden text-left tablet:col-span-6 tablet:col-start-7'>
				<h1 className={`mb-3 tablet:mb-6 ${singleHeadingFontClassName?.title}`}>{post.post_title}</h1>

				{/* Events Info */}
				{displayDate ||
				displayOrganizer ||
				displayPlace ||
				displayPrice ||
				displayTag ||
				displaySite ? (
					<>
						<EventsInfo
							post={post}
							dates={dates}
							singleHeadingFontClassName={singleHeadingFontClassName}
							iconType={iconType}
							containerStyle={containerStyle}
							eventsInfoContainerType={eventsInfoContainerType}
							displayDate={displayDate}
							displayOrganizer={displayOrganizer}
							displayPlace={displayPlace}
							displayPrice={displayPrice}
							displayTag={displayTag}
							displaySite={displaySite}
						/>
					</>
				) : (
					''
				)}
			</div>
		</div>
	);
}
