import type { ContentClass, PostSingleVariations } from '@/_types/Shared/Common';
import type { PostType } from '@/_types/Shared/Post';

type SingleBlockProps = {
	post: PostType;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	displayAuthor?: boolean;
	displayDate?: boolean;
	displayTag?: boolean;
};

export default function SingleBlock(props: SingleBlockProps) {
	const {
		post,
		variant = 'block',
		singleHeadingFontClassName,
		displayAuthor,
		displayDate,
		displayTag,
	} = props;

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative z-[11] col-start-2 col-end-13 m-auto sm:col-end-14 tablet:col-span-10 tablet:col-start-3`}
		>
			<div className='relative overflow-hidden'>
				{post.image && post.image.url ? (
					<img
						className='aspect-[5/3] w-full rounded-minos-single-news-img object-cover'
						src={post.image.url}
						alt=''
					/>
				) : (
					''
				)}
				<div className='absolute inset-x-0 bottom-0 z-10 w-full rounded-b-minos-single-news-img bg-minos-single-news-secondary-color p-5 tablet:p-7'>
					<div className={`mb-3 inline-flex flex-wrap gap-1 ${singleHeadingFontClassName?.text?.p}`}>
						{displayDate && (
							<>
								{post.post_date && (
									<>
										<time className='' dateTime={post?.post_date}>
											{post?.date_format}
										</time>

										{displayAuthor || displayTag ? (
											<>
												<span aria-hidden> | </span>
											</>
										) : (
											''
										)}
									</>
								)}
							</>
						)}

						{displayTag && (
							<>
								{post.categories?.length ? (
									<ul className='inline-flex'>
										{post.categories?.map((tag: string, index: number) => (
											<li key={index}> {(index ? ', ' : '') + tag} </li>
										))}
									</ul>
								) : (
									''
								)}
							</>
						)}

						{displayAuthor && (
							<>
								{post.author && (
									<>
										{(displayDate && displayTag) || displayTag ? (
											<>
												<span aria-hidden> | </span>
											</>
										) : (
											''
										)}
										<span>{post.author}</span>
									</>
								)}
							</>
						)}
					</div>
					<h1 className={singleHeadingFontClassName?.title}>{post.post_title}</h1>
				</div>
			</div>
		</div>
	);
}
