import type { ContentClass, PostSingleVariations } from '@/_types/Shared/Common';
import type { ImgFormatVariations } from '@/_types/Shared/Image';
import type { PostType } from '@/_types/Shared/Post';

type SingleTitleImgAlignProps = {
	post: PostType;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	imgType?: ImgFormatVariations;
	displayAuthor?: boolean;
	displayDate?: boolean;
	displayTag?: boolean;
};

export default function SingleTitleImgAlign(props: SingleTitleImgAlignProps) {
	const {
		post,
		variant = 'titleImgAlign',
		singleHeadingFontClassName,
		imgType = 'squared',
		displayAuthor,
		displayDate,
		displayTag,
	} = props;

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative z-[11] col-start-2 col-end-12 grid grid-cols-4 gap-8 sm:col-end-14 tablet:col-start-3 tablet:grid-cols-12`}
		>
			<div className='relative col-span-full tablet:col-span-6 tablet:col-start-1'>
				{post.image && post.image.url ? (
					<>
						<img
							className={`aspect-square size-full object-cover ${
								imgType === 'squared' ? 'rounded-minos-single-news-img' : 'rounded-full'
							}`}
							src={post.image.url}
							alt=''
						/>
					</>
				) : (
					''
				)}
			</div>

			<div className='col-span-full flex flex-col justify-center overflow-hidden text-left tablet:col-span-6 tablet:col-start-7'>
				<h1 className={`mb-3 tablet:mb-6 ${singleHeadingFontClassName?.title}`}>{post.post_title}</h1>

				<div className={singleHeadingFontClassName?.text?.p}>
					{displayDate && (
						<>
							{post.post_date && (
								<>
									<time className='' dateTime={post?.post_date}>
										{post?.date_format}
									</time>

									{displayAuthor || displayTag ? (
										<>
											<span aria-hidden> | </span>
										</>
									) : (
										''
									)}
								</>
							)}
						</>
					)}

					{displayTag && (
						<>
							{post.categories?.length ? (
								<ul className='inline-flex'>
									{post.categories?.map((tag: string, index: number) => (
										<li key={index}> {(index ? ', ' : '') + tag} </li>
									))}
								</ul>
							) : (
								''
							)}
						</>
					)}

					{displayAuthor && (
						<>
							{post.author && (
								<>
									{(displayDate && displayTag) || displayTag ? (
										<>
											<span aria-hidden> | </span>
										</>
									) : (
										''
									)}
									<span>{post.author}</span>
								</>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
