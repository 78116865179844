import type { eventTimeInfo } from '@/_types/Shared/advanced-block-page/EventSingle';
import type {
	ContainerTypeEnums,
	ContentClass,
	IconTypeEnums,
	PostSingleVariations,
} from '@/_types/Shared/Common';
import type { QueryPost } from '@/_types/Shared/Query';

import EventsInfo from '../components/EventsInfo';

type SingleSuperpositionProps = {
	post: QueryPost;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	dates?: eventTimeInfo[];
	iconType?: IconTypeEnums;
	eventsInfoContainerType?: ContainerTypeEnums;
	displayOrganizer?: boolean;
	displayDate?: boolean;
	displayTag?: boolean;
	displayPrice?: boolean;
	displayPlace?: boolean;
	displaySite?: boolean;
};

export default function SingleSuperposition(props: SingleSuperpositionProps) {
	const {
		post,
		variant = 'superposition',
		singleHeadingFontClassName,
		dates,
		iconType,
		eventsInfoContainerType,
		displayDate,
		displayOrganizer,
		displayPlace,
		displayPrice,
		displaySite,
		displayTag,
	} = props;

	let containerStyle = '';
	if (eventsInfoContainerType === 'block') {
		containerStyle =
			'rounded-b-minos-single-events-img border-2 bg-minos-single-events-info-bg-color border-minos-single-events-info-border-color p-6 tablet:p-12';
	} else {
		containerStyle = 'mt-6 tablet:mt-12';
	}

	let imgStyle = '';
	if (post.price || dates || post.link) {
		if (eventsInfoContainerType === 'block') {
			imgStyle = 'rounded-t-minos-single-events-img';
		} else {
			imgStyle = 'rounded-minos-single-events-img';
		}
	} else {
		imgStyle = 'rounded-minos-single-events-img';
	}

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative bg-minos-single-events-bg-color py-3.5 tablet:py-6 desktop:py-12`}
		>
			<div
				aria-hidden
				className='absolute left-0 top-0 z-0 h-[70%] w-full bg-minos-single-events-secondary-color tablet:h-[45%]'
			></div>

			<div className='minos-grid relative z-[11]' data-aos='fade-up'>
				<div className='relative z-[11] col-span-12 col-start-2 tablet:col-span-10 tablet:col-start-3'>
					<h1 className={`mb-3 tablet:mb-6 desktop:mb-7 ${singleHeadingFontClassName?.title}`}>
						{post.post_title}
					</h1>

					{post.image && post.image.url ? (
						<img className={`aspect-[5/3] w-full object-cover ${imgStyle}`} src={post.image.url} alt='' />
					) : (
						''
					)}

					{/* Events Info */}
					{displayDate ||
					displayOrganizer ||
					displayPlace ||
					displayPrice ||
					displayTag ||
					displaySite ? (
						<>
							<EventsInfo
								post={post}
								dates={dates}
								singleHeadingFontClassName={singleHeadingFontClassName}
								iconType={iconType}
								containerStyle={containerStyle}
								eventsInfoContainerType={eventsInfoContainerType}
								displayDate={displayDate}
								displayOrganizer={displayOrganizer}
								displayPlace={displayPlace}
								displayPrice={displayPrice}
								displayTag={displayTag}
								displaySite={displaySite}
							/>
						</>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
}
