import type { ContentClass, PostSingleVariations } from '@/_types/Shared/Common';
import type { QueryPost } from '@/_types/Shared/Query';

type SingleWideProps = {
	post: QueryPost;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	displayFunction?: boolean;
	displayMail?: boolean;
	displayPhone?: boolean;
	displaySocials?: boolean;
};

export default function SingleWide(props: SingleWideProps) {
	const {
		post,
		variant = 'classic',
		singleHeadingFontClassName,
		displayFunction,
		displayMail,
		displayPhone,
		displaySocials,
	} = props;

	const socialsClass =
		'minos-teams-social-size text-base tablet:minos-teams-social-size hover:text-minos-teams-social-color-hover';

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative z-[11] col-start-2 col-end-13 rounded-b-minos-single-teams-img bg-minos-single-teams-bg-color sm:col-end-14`}
		>
			<h1 className={`mb-4 tablet:mb-6 ${singleHeadingFontClassName?.title}`}>{post.post_title}</h1>

			<div className={`mx-auto mb-5 tablet:mb-6 desktop:mb-12 ${singleHeadingFontClassName?.text?.p}`}>
				<div className={`mb-3 tablet:mb-6 desktop:mb-7 ${singleHeadingFontClassName?.text?.p}`}>
					<div className='mb-5 flex flex-wrap gap-1'>
						{displayFunction && (
							<p className='flex flex-wrap gap-3 align-middle'>
								{post.tag?.map((value: string, index: number) => (
									<span key={index}>
										{value}
										{post.tag && post.tag?.length > 1 && index < 1 && ','}
									</span>
								))}
							</p>
						)}

						<div className='ml-auto flex flex-wrap gap-5'>
							{displaySocials && post.socials && post.socials?.length > 0 && (
								<>
									{post.socials?.map((social: any, i: number) => (
										<div key={i}>
											<a href={social.url} className={socialsClass} target='_blank' rel='noreferrer'>
												<span className='sr-only'>{social.name}</span>
												{social.icon && <i className={social.icon}></i>}
											</a>
										</div>
									))}
								</>
							)}
						</div>
					</div>

					{displayMail && (
						<>
							{post.emailInfos && post.emailInfos.email && (
								<>
									<p>
										Email: <a href={'mailto:' + post.emailInfos.email}>{post.emailInfos.email}</a>
									</p>
								</>
							)}
						</>
					)}

					{displayPhone && (
						<>
							{post.phoneInfos && post.phoneInfos.phone && (
								<>
									<p>
										Phone: <a href={'tel:' + post.phoneInfos.phone}>{post.phoneInfos.phone}</a>
									</p>
								</>
							)}
						</>
					)}
				</div>
			</div>

			{post.image && post.image.url ? (
				<img
					className='aspect-[1/1] w-full rounded-minos-single-teams-img object-cover'
					src={post.image.url}
					alt=''
				/>
			) : (
				''
			)}

			<div className='mx-auto max-w-3xl pt-10'>{post.content}</div>
		</div>
	);
}
