import { __Front as __ } from '@Shared/lib';

import { ResetBtn } from '@/views/Shared/Button/ResetBtn';
import Filter from '@/views/Shared/Filters/Filter';

import type { FiltersPostType } from '../Listing';

export const Filters = (props: FiltersPostType) => {
	const {
		categories,
		tags,
		setSelectedCategorie,
		setSelectedTag,
		selectedCategorie,
		selectedTag,
		filtersSelection,
		resetButtonStyle,
		listingNews,
	} = props;

	const CatFilter = {
		terms: categories,
		noResultLabel: __('No categorie found'),
		searchLabel: __('Search for categories'),
		selectLabel: __('Select a category'),
		setValue: setSelectedCategorie,
		value: selectedCategorie,
		selectClass:
			'minos-listing-news-filters-font-style text-base tablet:minos-listing-news-filters-font-style bg-minos-listing-news-filters-bg-color border-2 border-minos-listing-news-filters-border-color rounded-minos-news-filters-radius',
		actifClass: 'bg-minos-listing-news-filters-actif-color',
	};

	const TagFilter = {
		terms: tags,
		noResultLabel: __('No tag found'),
		searchLabel: __('Search for tags'),
		selectLabel: __('Select a tag'),
		setValue: setSelectedTag,
		value: selectedTag,
		selectClass:
			'minos-listing-news-filters-font-style text-base tablet:minos-listing-news-filters-font-style bg-minos-listing-news-filters-bg-color border-2 border-minos-listing-news-filters-border-color rounded-minos-news-filters-radius',
		actifClass: 'bg-minos-listing-news-filters-actif-color',
	};

	let style = '';
	if (listingNews.headingVariant != 'leftInline') {
		style = 'block text-center tablet:flex tablet:flex-wrap tablet:items-center tablet:gap-4';
	} else {
		style = 'flex flex-wrap items-center gap-4';
	}

	let margin = '';
	if (listingNews.headingVariant != 'leftInline') {
		margin = 'mt-4 tablet:mt-0';
	} else {
		margin = '';
	}

	return (
		<div className={`my-4 ${style}`}>
			{filtersSelection.categories && (
				<div className='w-full tablet:w-fit'>
					<Filter {...CatFilter} />
				</div>
			)}
			{filtersSelection.tags && (
				<div className={`w-full tablet:w-fit ${margin}`}>
					<Filter {...TagFilter} />
				</div>
			)}

			{selectedCategorie || selectedTag ? (
				<div className={`w-full tablet:w-fit ${margin}`}>
					<ResetBtn
						className='minos-listing-news-reset-btn text-base tablet:minos-listing-news-reset-btn'
						btnHasIcon={resetButtonStyle?.btnHasIcon}
						btnDecorationElement={resetButtonStyle?.btnDecorationElement}
						animation={resetButtonStyle?.animation}
						resetSelected={() => {
							setSelectedTag('');
							setSelectedCategorie('');
						}}
					/>
				</div>
			) : (
				''
			)}
		</div>
	);
};
