import { __Front as __ } from '@Shared/lib';

import { ResetBtn } from '@/views/Shared/Button/ResetBtn';
import Filter from '@/views/Shared/Filters/Filter';

import type { FiltersTeamsType } from '../Listing';

export const Filters = (props: FiltersTeamsType) => {
	const { functions, setSelectedFunctions, selectedFunctions, listingTeams } = props;

	const TypesFilter = {
		terms: functions,
		noResultLabel: __('No function found'),
		searchLabel: __('Search for functions'),
		selectLabel: __('Select a function'),
		setValue: setSelectedFunctions,
		value: selectedFunctions,
		selectClass:
			'minos-listing-teams-filters-font-style bg-minos-listing-teams-filters-bg-color border-2 border-minos-listing-teams-filters-border-color rounded-minos-teams-filters-radius',
		actifClass: 'bg-minos-listing-teams-filters-actif-color',
	};

	let style = '';
	if (listingTeams.headingVariant != 'leftInline') {
		style = 'block text-center tablet:flex tablet:flex-wrap tablet:items-center tablet:gap-4';
	} else {
		style = 'flex flex-wrap items-center gap-4';
	}

	let margin = '';
	if (listingTeams.headingVariant != 'leftInline') {
		margin = 'mt-4 tablet:mt-0';
	} else {
		margin = '';
	}

	return (
		<div className={`my-4 ${style}`}>
			<div className='w-full tablet:w-fit'>
				<Filter {...TypesFilter} />
			</div>

			{selectedFunctions && (
				<div className={`w-full tablet:w-fit ${margin}`}>
					<ResetBtn
						className='minos-listing-news-reset-btn tablet:minos-listing-news-reset-btn'
						btnHasIcon={listingTeams.resetButtonStyle?.btnHasIcon}
						btnDecorationElement={listingTeams.resetButtonStyle?.btnDecorationElement}
						resetSelected={() => {
							setSelectedFunctions('');
						}}
					/>
				</div>
			)}
		</div>
	);
};
