import type { eventTimeInfo } from '@/_types/Shared/advanced-block-page/EventSingle';
import type {
	ContainerTypeEnums,
	ContentClass,
	IconTypeEnums,
	PostSingleVariations,
} from '@/_types/Shared/Common';
import type { QueryPost } from '@/_types/Shared/Query';

import EventsInfo from '../components/EventsInfo';

type SingleBlockProps = {
	post: QueryPost;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	dates?: eventTimeInfo[];
	iconType?: IconTypeEnums;
	eventsInfoContainerType?: ContainerTypeEnums;
	displayOrganizer?: boolean;
	displayDate?: boolean;
	displayTag?: boolean;
	displayPrice?: boolean;
	displayPlace?: boolean;
	displaySite?: boolean;
};

export default function SingleBlock(props: SingleBlockProps) {
	const {
		post,
		variant = 'block',
		singleHeadingFontClassName,
		dates,
		iconType,
		eventsInfoContainerType,
		displayDate,
		displayOrganizer,
		displayPlace,
		displayPrice,
		displayTag,
		displaySite,
	} = props;

	let containerStyle = '';
	if (eventsInfoContainerType === 'block') {
		containerStyle =
			'rounded-b-minos-single-events-img border-x-2 border-b-2 bg-minos-single-events-info-bg-color border-minos-single-events-info-border-color p-6 tablet:p-12';
	} else {
		containerStyle = 'mt-6 tablet:mt-12';
	}

	let imgStyle = '';
	if (post.price || dates || post.link) {
		if (eventsInfoContainerType === 'block') {
			imgStyle = 'rounded-t-minos-single-events-img';
		} else {
			imgStyle = 'rounded-minos-single-events-img';
		}
	} else {
		imgStyle = 'rounded-minos-single-events-img';
	}

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative z-[11] col-start-2 col-end-13 m-auto sm:col-end-14 tablet:col-span-10 tablet:col-start-3`}
		>
			<div className='relative overflow-hidden'>
				{post.image && post.image.url ? (
					<img className={`aspect-[5/3] w-full object-cover ${imgStyle}`} src={post.image.url} alt='' />
				) : (
					''
				)}
				<div className='absolute inset-x-0 bottom-0 z-10 w-full bg-minos-single-events-secondary-color p-5 tablet:p-7'>
					<h1 className={singleHeadingFontClassName?.title}>{post.post_title}</h1>
				</div>
			</div>

			{/* Events Info */}
			{displayDate || displayOrganizer || displayPlace || displayPrice || displayTag || displaySite ? (
				<>
					<EventsInfo
						post={post}
						dates={dates}
						singleHeadingFontClassName={singleHeadingFontClassName}
						iconType={iconType}
						containerStyle={containerStyle}
						eventsInfoContainerType={eventsInfoContainerType}
						displayDate={displayDate}
						displayOrganizer={displayOrganizer}
						displayPlace={displayPlace}
						displayPrice={displayPrice}
						displayTag={displayTag}
						displaySite={displaySite}
					/>
				</>
			) : (
				''
			)}
		</div>
	);
}
