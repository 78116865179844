import Layout from '@Shared/Layout/Layout';

import type { TeamsOptions } from '@/_types/Blocks/Teams';
import type {
	SingleTeamsOptions,
	SingleTeamsType,
} from '@/_types/Shared/advanced-block-page/TeamSingle';
import type { SectionDecorationOptions } from '@/_types/Shared/Common';
import type { Options } from '@/_types/Shared/Options';
import type { QueryPost } from '@/_types/Shared/Query';
import type { SEO } from '@/_types/Shared/Seo';
import type { MenusType, SiteType } from '@/_types/Shared/SiteDatas';
import Decorations from '@/App/Blocks/_components/Decorations';
import { __Front as __ } from '@/views/Shared/lib';
import type { Translation } from '@/views/Shared/Translations';

import CardsTypes from './components/CardsTypes';
import SingleBlock from './variants/SingleBlock';
import SingleClassic from './variants/SingleClassic';
import SingleSuperposition from './variants/SingleSuperposition';
import SingleTitleImgAlign from './variants/SingleTitleImgAlign';
import SingleWide from './variants/SingleWide';

type SingleType = {
	post: QueryPost;
	site: SiteType;
	menus: MenusType;
	minos: Options;
	relatedTeams: QueryPost[];
	translations: Translation[];
	seo: SEO;
};

export default function Single(props: SingleType) {
	const { post, relatedTeams, site, menus, minos, translations, seo } = props;

	const singleTeam: SingleTeamsOptions = { ...minos.layout.teams.single };
	const blockTeams: TeamsOptions = { ...minos.blocks.teams.default };
	const sectionDecoration: SectionDecorationOptions = { ...minos.layout.sectionDecoration };

	const propsSingle: SingleTeamsType = {
		post: post,
		variant: singleTeam.variant,
		imgType: singleTeam.imgType,
		displayFunction: singleTeam.displayFunction,
		displayMail: singleTeam.displayMail,
		displayPhone: singleTeam.displayPhone,
		displaySocials: singleTeam.displaySocials,
		singleHeadingFontClassName: {
			title:
				'minos-single-teams-heading-title-font-style text-4xl tablet:minos-single-teams-heading-title-font-style ',

			text: {
				p: 'minos-single-teams-info-font-style text-base tablet:minos-single-teams-info-font-style',
			},
		},
	};

	const singleVariations = {
		classic: <SingleClassic {...propsSingle} />,
		block: <SingleBlock {...propsSingle} />,
		superposition: <SingleSuperposition {...propsSingle} />,
		titleImgAlign: <SingleTitleImgAlign {...propsSingle} />,
		wide: <SingleWide {...propsSingle} />,
	};

	let containerStyle = '';

	if (singleTeam.variant === 'block') {
		containerStyle = 'minos-grid pt-5 tablet:pt-6 desktop:pt-12 bg-minos-single-teams-bg-color';
	} else if (singleTeam.variant === 'superposition') {
		containerStyle = '';
	} else {
		containerStyle = 'minos-grid py-5 tablet:py-12 bg-minos-single-teams-bg-color';
	}

	let padding = '';
	if (sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'wave') {
		padding = 'py-16 tablet:py-24 desktop:pb-40 desktop:pt-24';
	} else {
		padding = 'py-3.5 tablet:py-6 desktop:py-12';
	}

	let margin = '';
	if (sectionDecoration?.isSectionDecorated && sectionDecoration.type === 'wave') {
		margin = 'mt-10 mb-20 tablet:mt-20 tablet:mb-40';
	} else {
		margin = 'my-10 tablet:my-20';
	}

	let relatedTeamContainer = '';
	if (singleTeam.relatedVariant === 'classic') {
		relatedTeamContainer = `col-start-2 col-end-14 ${padding}`;
	} else {
		relatedTeamContainer = `col-start-2 col-end-14 py-5 sm:col-start-2 tablet:col-start-3 tablet:col-end-13 p-4 tablet:p-6 desktop:p-12 bg-minos-related-teams-secondary-color border-2 border-minos-related-teams-container-border-color rounded-minos-related-teams-container ${margin}`;
	}

	const Props: ViewProps = {
		containerStyle,
		singleVariations,
		singleTeam,
		sectionDecoration,
		blockTeams,
		relatedTeams,
		relatedTeamContainer,
	};

	return (
		<Layout
			blocks={['single-teams', 'teams']}
			seo={seo}
			site={site}
			translations={translations}
			menus={menus}
			minos={minos}
		>
			<View {...Props} />
		</Layout>
	);
}

type ViewProps = {
	containerStyle: string;
	singleVariations: any;
	singleTeam: SingleTeamsOptions;
	relatedTeams: QueryPost[];
	sectionDecoration: SectionDecorationOptions;
	relatedTeamContainer: string;
	blockTeams: TeamsOptions;
};

const View = (props: ViewProps) => {
	const {
		containerStyle,
		singleVariations,
		singleTeam,
		relatedTeams,
		sectionDecoration,
		relatedTeamContainer,
		blockTeams,
	} = props;

	return (
		<>
			<article>
				<div data-aos='fade-up' className={containerStyle}>
					{/* content */}
					{singleVariations[singleTeam.variant]}
				</div>
			</article>

			{/* Related posts */}
			{relatedTeams && relatedTeams.length > 0 ? (
				<div className='minos-grid relative bg-minos-related-teams-bg-color'>
					<Decorations
						isSectionDecorated={sectionDecoration?.isSectionDecorated}
						type={sectionDecoration?.type}
						pathClassName='fill-minos-related-teams-bg-color'
					>
						<aside
							data-related-variant={`relatedTeams-${singleTeam.relatedVariant}`}
							data-aos='fade-up'
							className={`relative z-[11] ${relatedTeamContainer}`}
						>
							<h2 className='minos-related-teams-title-font-style mb-6 text-3xl tablet:minos-related-teams-title-font-style tablet:mb-12'>
								{__('Related members')}
							</h2>

							<div className='grid grid-cols-4 gap-y-5 tablet:grid-cols-12 tablet:gap-8 '>
								{relatedTeams.map((post: QueryPost) => (
									<CardsTypes
										key={post.ID}
										post={post}
										cardType={blockTeams.cardType}
										variant={blockTeams.variant}
										imgType={blockTeams.imgType}
										displayTags={blockTeams.displayTags}
										displayMail={blockTeams.displayMail}
										displayPhone={blockTeams.displayPhone}
										displaySocials={blockTeams.displaySocials}
										// displayDesc={blockTeams.displayDesc}
									/>
								))}
							</div>
						</aside>
					</Decorations>
				</div>
			) : (
				''
			)}
		</>
	);
};
