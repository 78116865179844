// import __ from '@Blocks/_components/translations';

import type { ContentClass, PostSingleVariations } from '@/_types/Shared/Common';
import type { QueryPost } from '@/_types/Shared/Query';

type SingleBlockProps = {
	post: QueryPost;
	variant?: PostSingleVariations;
	singleHeadingFontClassName?: ContentClass;
	displayFunction?: boolean;
	displayMail?: boolean;
	displayPhone?: boolean;
	displaySocials?: boolean;
};

export default function SingleBlock(props: SingleBlockProps) {
	const {
		post,
		variant = 'block',
		singleHeadingFontClassName,
		displayFunction,
		displayMail,
		displayPhone,
		displaySocials,
	} = props;

	const socialsClass =
		'minos-teams-social-size text-base tablet:minos-teams-social-size hover:text-minos-teams-social-color-hover';

	return (
		<div
			data-variant={`single-${variant}`}
			className={`relative z-[11] col-start-2 col-end-14 tablet:col-span-10 tablet:col-start-3`}
		>
			<div className='relative overflow-hidden'>
				{post.image && post.image.url ? (
					<img
						className='aspect-[1/1] w-full rounded-t-minos-single-teams-img object-cover'
						src={post.image.url}
						alt=''
					/>
				) : (
					''
				)}
				<div className='absolute inset-x-0 bottom-0 z-10 w-full bg-minos-single-teams-secondary-color p-5 tablet:p-7'>
					<h1 className={singleHeadingFontClassName?.title}>{post.post_title}</h1>
					<div className={`mt-4 flex flex-col flex-wrap gap-1 ${singleHeadingFontClassName?.text?.p}`}>
						<div className='flex flex-wrap gap-1'>
							{displayFunction && (
								<>
									<p>
										{post.tag?.map((value: string, index: number) => <span key={index}> {value} </span>)}
									</p>
								</>
							)}

							<ul className='ml-auto flex flex-wrap gap-5'>
								{displaySocials && post.socials && post.socials?.length > 0 && (
									<>
										{post.socials?.map((social: any, i: number) => (
											<li key={i}>
												<a href={social.url} className={socialsClass} target='_blank' rel='noreferrer'>
													<span className='sr-only'>{social.name}</span>
													{social.icon && <i className={social.icon}></i>}
												</a>
											</li>
										))}
									</>
								)}
							</ul>
						</div>

						{displayMail && (
							<>
								{post.emailInfos && post.emailInfos.email && (
									<>
										<p>
											Email: <a href={'mailto:' + post.emailInfos.email}>{post.emailInfos.email}</a>
										</p>
									</>
								)}
							</>
						)}

						{displayPhone && (
							<>
								{post.phoneInfos && post.phoneInfos.phone && (
									<>
										<p>
											Phone: <a href={'tel:' + post.phoneInfos.phone}>{post.phoneInfos.phone}</a>
										</p>
									</>
								)}
							</>
						)}
					</div>
				</div>
			</div>

			<div className='mb-10 rounded-b-minos-single-teams-img bg-minos-single-teams-content-color px-5 py-4 tablet:px-12 tablet:py-11'>
				{post.content}
			</div>
		</div>
	);
}
